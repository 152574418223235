import * as THREE from 'three'
import {
  customBoundingBoxBySize,
  DropBox,
  dropBoxCreator,
  Item,
  OverlayAnchorX,
  OverlayAnchorY,
  OverlayAnchorZ,
  replaceIntersectedItemsOnDropBox,
  AttachedItem,
  ItemOptions,
  Restored,
  CSS2DObject,
} from '@teamsesam/configurator-core'
import { ItemProperties, ItemType } from '../config'
import { plateThickness, plinthPanelHeight, rollingDrawerHeight, traverseHeight } from '../constants'
import { EditColumn } from '../../components/EditColumn'
import { defaultInnerShadowOptions, InnerShadow } from '../shadows/inner/innerShadow'
import _ from 'lodash'

const calculateTrayPositionY = (y: number, minY: number, maxY: number): number => {
  const normalizedY = y - minY
  if (normalizedY < 6.5) {
    return 5 + minY
  }

  const trayIndexNotRound = (normalizedY - 5) / 3
  let trayIndex = Math.round(trayIndexNotRound)
  if (trayIndex % 9 === 3 || trayIndex % 9 === 8) {
    trayIndex = trayIndexNotRound < trayIndex ? trayIndex - 1 : trayIndex + 1
  }

  const newY = trayIndex * 3 + 5 + minY
  const maxItemY = maxY - plateThickness / 2
  return newY > maxItemY ? maxItemY : newY
}

const calculateFlapPositionY = (y1: number, itemHeight: number, minY: number, maxY: number): number => {
  const unitHeights = Math.round(itemHeight / 3)

  const minItemY = minY + 5 + (unitHeights / 2) * 3
  if (y1 < minItemY) {
    return minItemY
  }

  const maxItemY = maxY - 1 - (unitHeights / 2) * 3
  let y = y1 > maxItemY ? maxItemY : y1

  const normalizedY = y - minY
  const trayIndexNotRound = (normalizedY - 5) / 3 - 8
  let trayIndex = Math.round(trayIndexNotRound)
  if (trayIndex % 9 === 3 || trayIndex % 9 === 8) {
    trayIndex = trayIndexNotRound < trayIndex ? trayIndex - 1 : trayIndex + 1
  }

  const newY = (trayIndex + 8) * 3 + 5 + minY - 1.5
  return newY
}

const calculateInteriorPositionY = (y: number, itemHeight: number, minY: number, maxY: number): number => {
  const unitHeights = Math.round(itemHeight / 3)

  const minItemY = minY + 5 + (unitHeights / 2) * 3
  if (y < minItemY) {
    return minItemY
  }

  const maxItemY = maxY - 1 - (unitHeights / 2) * 3
  if (y > maxItemY) {
    return maxItemY
  }

  const offsetY = unitHeights % 2 === 0 ? 0 : 1.5
  return Math.round((y - minY - 5 - offsetY) / 3) * 3 + 5 + offsetY + minY
}

const calculatePositionY = (y: number, itemTypeId: string, itemHeight: number, minY: number, maxY: number) => {
  if (itemTypeId === ItemType.RollingDrawer) {
    return minY + rollingDrawerHeight / 2 + 2.7
  }
  if (itemTypeId === ItemType.PlinthPanel) {
    return minY + plinthPanelHeight / 2
  }
  if (itemTypeId === ItemType.ShelfBackWall) {
    return y
  }
  if (itemTypeId === ItemType.Tray) {
    return calculateTrayPositionY(y, minY, maxY)
  }
  if (itemTypeId === ItemType.FlapSet) {
    return calculateFlapPositionY(y, itemHeight, minY, maxY)
  }
  return calculateInteriorPositionY(y, itemHeight, minY, maxY)
}

export const columnDropBox: dropBoxCreator = (context) => {
  const { item, on, createItem, subscribe } = context

  let duringLoading = item.isImported()
  subscribe(Restored, () => {
    duringLoading = false
  })

  let plinthPanel: Item | undefined
  const shadows: InnerShadow[] = []
  const innerMeasurements: Item[] = []

  item.customBoundingBox = customBoundingBoxBySize(
    () => item.getPropertyValue(ItemProperties.ColumnWidth),
    () => item.getPropertyValue(ItemProperties.ColumnHeight),
    () => item.getPropertyValue(ItemProperties.ShelfDepth),
  )

  on.calculateChildPosition((childItem, newPosition) => {
    const y = calculatePositionY(newPosition.y, childItem.configItem.typeId, childItem.size.y, -item.halfSize.y, item.halfSize.y)
    return new THREE.Vector3(0, y, 0)
  })

  const editColumnOverlayItemId = item.overlays.addOverlay({
    component: EditColumn,
    anchors: { x: OverlayAnchorX.center, y: OverlayAnchorY.center, z: OverlayAnchorZ.center },
    props: { item },
  })
  const editColumnOverlayItem = item.children.find((child) => child.uuid === editColumnOverlayItemId) as CSS2DObject

  const updateSize = () => {
    const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth)
    const columnHeight = item.getPropertyValue(ItemProperties.ColumnHeight)
    const shelfDepth = item.getPropertyValue(ItemProperties.ShelfDepth)

    const size = new THREE.Vector3(columnWidth, columnHeight, shelfDepth)
    item.setSize(size)

    item
      .getItems()
      .forEach((childItem) =>
        childItem.setProperties({ [ItemProperties.ColumnWidth]: columnWidth, [ItemProperties.ColumnHeight]: columnHeight }),
      )

    editColumnOverlayItem.offset3D = new THREE.Vector3(0, columnHeight / -2, shelfDepth / 2)
  }

  const updateInnerMeasurements = () => {
    const itemsBottomToTop = _.sortBy(
      item.getItems().filter((child) => child.isType('Tray') || child.isType('Drawer')),
      (child) => child.position.y,
    )

    const toRemove = innerMeasurements.splice(itemsBottomToTop.length - 1)
    toRemove.forEach((measurement) => item.remove(measurement))

    for (let i = innerMeasurements.length; i < itemsBottomToTop.length - 1; i++) {
      const measurement = createItem(ItemType.InnerMeasurement)
      innerMeasurements.push(measurement)
      item.addWithoutNotification(measurement)
    }

    if (itemsBottomToTop.length > 0) {
      let lastItemMaxY = itemsBottomToTop[0].getMax().y
      for (let i = 1; i < itemsBottomToTop.length; i++) {
        const minY = itemsBottomToTop[i].getMin().y
        const maxY = itemsBottomToTop[i].getMax().y
        const measurement = innerMeasurements[i - 1]
        const height = minY - lastItemMaxY
        measurement.position.y = lastItemMaxY + height / 2
        measurement.setProperty(ItemProperties.InnerMeasurementHeight, height)
        lastItemMaxY = maxY
      }
    }
  }

  const getDrawerConnectedTrays = () => {
    const trays = item.getItems(ItemType.Tray)
    const dimensions = getDrawerStacksDimensions(item)
    const connectedTrays: Item[] = []
    dimensions.forEach((dimension) => {
      const existingTopDrawer = trays.find((tray) => Math.abs(tray.position.y - dimension.maxY) < 2)
      if (existingTopDrawer) {
        connectedTrays.push(existingTopDrawer)
      }
      const existingBottomDrawer = trays.find((tray) => Math.abs(tray.position.y - dimension.minY) < 2)
      if (existingBottomDrawer) {
        connectedTrays.push(existingBottomDrawer)
      }
    })
    return connectedTrays
  }

  const updateFixTraysAndTraversesOnColumn = () => {
    const hasShelfBackWall = item.getItems(ItemType.ShelfBackWall).length > 0
    const minFixTrayCount = item.getPropertyValue(ItemProperties.ColumnHeight) >= 162 ? 3 : 2
    const traysTopToBottom = _.orderBy(item.getItems(ItemType.Tray), (tray) => tray.position.y, 'desc')
    const flapTrays = traysTopToBottom.filter((tray) => (tray as AttachedItem).attachedBy)
    const traverses = item.getItems(ItemType.Traverse)
    const interiorBackWall = item.getItems(ItemType.InteriorBackWall)
    const columnMinY = -item.halfSize.y

    const bottomTray = _.last(traysTopToBottom)
    let trayWithTraverse: Item | undefined = undefined
    if (bottomTray) {
      const topTray = traysTopToBottom[0]
      trayWithTraverse = flapTrays.length > 0 ? _.last(flapTrays) : topTray
      const fixedTrays = [...flapTrays, ...getDrawerConnectedTrays(), bottomTray, topTray]
      let oneMoreFixedTrayNeeded = fixedTrays.length < minFixTrayCount

      for (let index = 0; index < traysTopToBottom.length; index++) {
        const tray = traysTopToBottom[index]
        if (fixedTrays.includes(tray)) {
          tray.setProperty(ItemProperties.IsFixTray, true)
        } else if (oneMoreFixedTrayNeeded && (tray.position.y - columnMinY < 92 || index === traysTopToBottom.length - 2)) {
          tray.setProperty(ItemProperties.IsFixTray, true)
          trayWithTraverse = tray
          oneMoreFixedTrayNeeded = false
        } else {
          tray.setProperty(ItemProperties.IsFixTray, false)
        }
      }
    }

    let traverseTop: number | undefined
    if (!hasShelfBackWall && interiorBackWall.length === 0 && trayWithTraverse) {
      traverseTop = trayWithTraverse.getMin().y
    }
    if (traverseTop !== undefined) {
      const traverseY = traverseTop - traverseHeight / 2
      if (traverses.length > 0) {
        traverses[0].position.y = traverseY
      } else {
        const traverse = createItem(ItemType.Traverse, {
          position: new THREE.Vector3(0, traverseY, 0),
          properties: { [ItemProperties.ColumnWidth]: item.getPropertyValue(ItemProperties.ColumnWidth) },
        })
        item.add(traverse)
      }
    } else {
      if (traverses.length > 0) {
        traverses.forEach((traverse) => traverse.parentItem.remove(traverse))
      }
    }
  }

  const updateDefaultTrays = () => {
    const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth)

    const addDefaultTrays = item
      .getDraggableItems()
      .every(
        (item) =>
          item.configItem.typeId === ItemType.Tray ||
          item.configItem.typeId === ItemType.DoorSet ||
          item.configItem.typeId === ItemType.ShelfBackWall ||
          item.configItem.typeId === ItemType.PlinthPanel,
      )
    if (addDefaultTrays) {
      item.getItems(ItemType.Tray).forEach((tray) => item.remove(tray))

      const columnHeight = item.getPropertyValue(ItemProperties.ColumnHeight)
      const defaultTrayCount = item.getPropertyValue(ItemProperties.DefaultTrayCount)
      const spaceForTrays = columnHeight - plateThickness / 2 - 5
      const spaceBetweenTrays = spaceForTrays / (defaultTrayCount - 1)
      let y = columnHeight / -2 + 5
      for (let i = 0; i < defaultTrayCount; i++) {
        const tray = createItem(ItemType.Tray, {
          properties: { [ItemProperties.ColumnWidth]: columnWidth },
        })
        tray.position.y = calculatePositionY(y, tray.configItem.typeId, tray.size.y, -item.halfSize.y, item.halfSize.y)
        item.add(tray)
        y += spaceBetweenTrays
      }
    } else {
      const topTray = _.maxBy(item.getItems(ItemType.Tray), (item) => item.position.y)
      if (topTray && item.getMax().y - topTray.getMax().y > 1) {
        const newTopTray = createItem('Tray')
        newTopTray.position.y = item.getMax().y - 1
        item.add(newTopTray)
      }
    }
  }

  const updatePlinthPanel = () => {
    const withPlinthPanel = item.getPropertyValue(ItemProperties.WithPlinthPanel)
    const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth)
    const bottomTray = _.minBy(item.getItems('Tray'), (item) => item.position.y)

    if (withPlinthPanel && bottomTray && bottomTray.position.y === -item.halfSize.y + 5) {
      if (!plinthPanel) {
        plinthPanel = createItem(ItemType.PlinthPanel, {
          properties: { [ItemProperties.ColumnWidth]: columnWidth },
        })
        item.add(plinthPanel)
      } else {
        plinthPanel.setProperties({ [ItemProperties.ColumnWidth]: columnWidth })
      }
    } else {
      if (plinthPanel) {
        item.remove(plinthPanel)
        plinthPanel = undefined
      }
    }
  }

  const updateShadows = () => {
    const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth)
    const shelfDepth = item.getPropertyValue(ItemProperties.ShelfDepth)

    const traysBottomToTop = _.sortBy(item.getItems(ItemType.Tray), (tray) => tray.position.y)

    const shadowsToRemove = shadows.splice(traysBottomToTop.length)
    shadowsToRemove.forEach((shadow) => item.remove(shadow))
    for (let i = shadows.length; i < traysBottomToTop.length; i++) {
      const shadow = new InnerShadow(defaultInnerShadowOptions)
      if (i === 0) {
        shadow.hasBottomShadow = false
      }
      shadows.push(shadow)
      item.add(shadow)
    }

    let lastTrayMaxY = -item.halfSize.y
    traysBottomToTop.forEach((tray, index) => {
      const shadow = shadows[index]
      const spaceBetweenTrays = tray.getMin().y - lastTrayMaxY
      shadow.position.y = tray.getMin().y - spaceBetweenTrays / 2
      shadow.setSize(new THREE.Vector3(columnWidth - plateThickness, spaceBetweenTrays, shelfDepth))
      lastTrayMaxY = tray.getMax().y
    })
  }

  const updateShadowsAndInnerMeasurements = () => {
    updateShadows()
    updateInnerMeasurements()
  }

  updateSize()
  updateBackWalls(item, createItem)
  if (!item.isImported()) {
    updateDefaultTrays()
    updatePlinthPanel()
    updateFixTraysAndTraversesOnColumn()
  }
  updateShadowsAndInnerMeasurements()

  on.propertyChanged([ItemProperties.ColumnHeight, ItemProperties.ShelfDepth, ItemProperties.ColumnWidth], updateSize)
  on.propertyChanged([ItemProperties.ColumnHeight, ItemProperties.DefaultTrayCount], updateDefaultTrays)
  on.propertyChanged([ItemProperties.ColumnHeight, ItemProperties.ShelfDepth, ItemProperties.HasShelfBackWall], () =>
    updateBackWalls(item, createItem),
  )
  on.propertyChanged(
    [ItemProperties.ColumnHeight, ItemProperties.ShelfDepth, ItemProperties.DefaultTrayCount, ItemProperties.HasShelfBackWall],
    updateFixTraysAndTraversesOnColumn,
  )

  on.propertyChanged([ItemProperties.ColumnWidth, ItemProperties.WithPlinthPanel], updatePlinthPanel)
  on.itemLeaved(updatePlinthPanel)
  on.itemMoved(updatePlinthPanel)

  on.propertyChanged(
    [ItemProperties.ColumnHeight, ItemProperties.ShelfDepth, ItemProperties.ColumnWidth, ItemProperties.DefaultTrayCount],
    updateShadowsAndInnerMeasurements,
  )
  on.itemLeaved(updateShadowsAndInnerMeasurements)
  on.itemMoved(updateShadowsAndInnerMeasurements)

  on.itemEntered((draggingItem) => {
    const columnWidth = item.getPropertyValue(ItemProperties.ColumnWidth)
    draggingItem.setProperty(ItemProperties.ColumnWidth, columnWidth)
  })

  on.itemDropped((droppedItem) => {
    replaceIntersectedItemsOnDropBox(item, droppedItem, replacingDefinitions)
    if (
      droppedItem.isType(ItemType.Drawer) ||
      droppedItem.isType(ItemType.DoorSet) ||
      droppedItem.isType(ItemType.FlapSet) ||
      droppedItem.isType(ItemType.InteriorBackWall)
    ) {
      updateBackWalls(item, createItem)
    }
    if (!duringLoading && droppedItem.isType(ItemType.Drawer)) {
      addTraysOnTopAndBottomOfDrawers(item, createItem)
    }
    updateFixTraysAndTraversesOnColumn()
    updatePlinthPanel()
    updateShadowsAndInnerMeasurements()
  })
  on.itemLeaved((child) => {
    if (child.isType(ItemType.Drawer) || child.isType(ItemType.DoorSet) || child.isType(ItemType.FlapSet)) {
      updateBackWalls(item, createItem)
    }
    if (child.isType(ItemType.Drawer)) {
      addTraysOnTopAndBottomOfDrawers(item, createItem)
    }
    updateFixTraysAndTraversesOnColumn()
  })
  on.childItemRemoved((child) => {
    if (child.isType(ItemType.Drawer) || child.isType(ItemType.DoorSet) || child.isType(ItemType.FlapSet)) {
      updateBackWalls(item, createItem)
    }
    if (child.isType(ItemType.Drawer)) {
      addTraysOnTopAndBottomOfDrawers(item, createItem)
    }
    updateFixTraysAndTraversesOnColumn()
    updateShadowsAndInnerMeasurements()
  })
}

const interiorItems = [ItemType.Tray, ItemType.Drawer, ItemType.RollingDrawer]
const frontItems = [ItemType.Drawer, ItemType.RollingDrawer, ItemType.FlapSet, ItemType.DoorSet]

const replacingDefinitions = [
  { typeId: ItemType.Tray, replaces: interiorItems },
  { typeId: ItemType.Drawer, replaces: [...interiorItems, ...frontItems] },
  { typeId: ItemType.RollingDrawer, replaces: [...interiorItems, ...frontItems] },
  { typeId: ItemType.FlapSet, replaces: frontItems },
  { typeId: ItemType.DoorSet, replaces: frontItems },
  { typeId: ItemType.InteriorBackWall, replaces: [ItemType.InteriorBackWall] },
  { typeId: ItemType.ShelfBackWall, replaces: [ItemType.InteriorBackWall] },
]

export const updateDropBoxByDraggableItemResize = (item: Item) => {
  if (item.parent && !item.isRootItem && !item.isDragging) {
    const dropBox = item.parentItem as DropBox
    item.position.copy(dropBox.calculateChildPosition(item, item.position))
    replaceIntersectedItemsOnDropBox(dropBox, item, replacingDefinitions)
  }
}

const mergeDimensions = (dimensions: { minY: number; maxY: number }[]): { minY: number; maxY: number }[] => {
  const mergedDimensions: { minY: number; maxY: number }[] = []
  let minY: number | undefined = undefined
  let maxY: number | undefined = undefined
  for (const dimension of _.sortBy(dimensions, (dimension) => dimension.minY)) {
    if (minY === undefined || maxY === undefined) {
      minY = dimension.minY
      maxY = dimension.maxY
    } else if (dimension.minY <= maxY) {
      maxY = dimension.maxY
    } else {
      mergedDimensions.push({ minY, maxY })
      minY = dimension.minY
      maxY = dimension.maxY
    }
  }
  if (minY !== undefined && maxY !== undefined) {
    mergedDimensions.push({ minY, maxY })
  }
  return mergedDimensions
}

const getBackWallDimensions = (item: Item): { minY: number; maxY: number }[] => {
  if (item.getPropertyValue(ItemProperties.HasShelfBackWall)) {
    const backWallHeight = item.getPropertyValue(ItemProperties.ColumnHeight) - plateThickness
    return [{ minY: backWallHeight / -2, maxY: backWallHeight / 2 }]
  }
  const dimensions: { minY: number; maxY: number }[] = []
  for (const drawer of item.getItems(ItemType.Drawer)) {
    dimensions.push({ minY: drawer.getMin().y - 0.75, maxY: drawer.getMax().y + 0.75 })
  }
  for (const doorSet of item.getItems(ItemType.DoorSet)) {
    dimensions.push({ minY: doorSet.getMin().y - 0.75, maxY: doorSet.getMax().y + 0.75 })
  }
  for (const flapSet of item.getItems(ItemType.FlapSet)) {
    dimensions.push({ minY: flapSet.getMin().y - 0.75, maxY: flapSet.getMax().y + 0.75 })
  }
  return mergeDimensions(dimensions)
}

export const updateBackWalls = (dropBox: Item, createItem: (typeId: string, options?: ItemOptions) => Item) => {
  const columnWidth = dropBox.getPropertyValue(ItemProperties.ColumnWidth)
  const backWalls = dropBox.getItems(ItemType.ShelfBackWall)

  backWalls.forEach((backWall) => dropBox.remove(backWall))
  backWalls.splice(0, backWalls.length)

  const backWallDimensions = getBackWallDimensions(dropBox)
  backWallDimensions.forEach((dimension) => {
    const { minY, maxY } = dimension
    const backWallHeight = Math.round(maxY - minY)
    const backWall = createItem(ItemType.ShelfBackWall, {
      properties: { [ItemProperties.ShelfBackWallHeight]: backWallHeight, [ItemProperties.ColumnWidth]: columnWidth },
    })
    backWall.position.y = minY + backWallHeight / 2
    dropBox.add(backWall)
    backWalls.push(backWall)
  })
}

const getDrawerStacksDimensions = (dropBox: Item): { minY: number; maxY: number }[] => {
  const dimensions: { minY: number; maxY: number }[] = []
  for (const drawer of dropBox.getItems(ItemType.Drawer)) {
    dimensions.push({ minY: drawer.getMin().y - 0.75, maxY: drawer.getMax().y + 0.75 })
  }
  return mergeDimensions(dimensions)
}

const removeTraysBetweenDrawers = (dropBox: Item, trays: Item[], drawerStackDimensions: { minY: number; maxY: number }[]) => {
  drawerStackDimensions.forEach((dimension) => {
    const traysBetweenDrawers = trays.filter((tray) => tray.position.y < dimension.maxY - 1 && tray.position.y > dimension.minY + 1)
    traysBetweenDrawers.forEach((tray) => dropBox.remove(tray))
  })
}

export const addTraysOnTopAndBottomOfDrawers = (dropBox: Item, createItem: (typeId: string, options?: ItemOptions) => Item) => {
  const drawers = _.sortBy(dropBox.getItems(ItemType.Drawer), (drawer) => drawer.position.y)
  if (drawers.length === 0) {
    return
  }
  const trays = dropBox.getItems(ItemType.Tray)
  const dimensions = getDrawerStacksDimensions(dropBox)
  dimensions.forEach((dimension) => {
    const existingTopDrawer = trays.find((tray) => Math.abs(tray.position.y - dimension.maxY) < 2)
    if (!existingTopDrawer) {
      const topDrawer = createItem(ItemType.Tray)
      topDrawer.position.y = dimension.maxY
      dropBox.add(topDrawer)
    }

    const existingBottomDrawer = trays.find((tray) => Math.abs(tray.position.y - dimension.minY) < 2)
    if (!existingBottomDrawer) {
      const bottomDrawer = createItem(ItemType.Tray)
      bottomDrawer.position.y = dimension.minY
      dropBox.add(bottomDrawer)
    }
  })
  removeTraysBetweenDrawers(dropBox, trays, dimensions)
}
