import { Item, useItemProperties } from '@teamsesam/configurator-core'
import EditIcon from '@mui/icons-material/Edit'
import { useState } from 'react'
import { IconButton, Typography } from '@mui/material'
import { Horizontal } from '@teamsesam/core'
import { ItemProperties, ItemType } from '../three/config'
import { EditColumnDialog } from './EditColumnDialog'
import { plateThickness } from '../three/constants'

interface RemoveItemButtonProps {
  item: Item
}

export function EditColumn(props: RemoveItemButtonProps) {
  const { item } = props
  const { columnWidth, columnHeight } = useItemProperties(item, [ItemProperties.ColumnWidth, ItemProperties.ColumnHeight])
  const [open, setOpen] = useState(false)

  const shelfItem = item.parentItem
  const { fixColumnHeights, fixColumnWidths } = useItemProperties(shelfItem, [
    ItemProperties.GridConstruction,
    ItemProperties.FixColumnHeights,
    ItemProperties.FixColumnWidths,
  ])
  if (!fixColumnHeights && !fixColumnWidths && shelfItem && shelfItem.getItems(ItemType.ColumnDropBox).length === 1) {
    return null
  }

  return (
    <Horizontal style={{transform: 'translateY(25px)'}}>
      <IconButton style={{ pointerEvents: 'auto' }} onClick={() => setOpen(true)}>
        <EditIcon />
      </IconButton>
      <Typography noWrap style={{ fontSize: '12px' }}>
        {columnHeight} x {columnWidth - plateThickness}
      </Typography>
      {open && <EditColumnDialog item={item} onClose={() => setOpen(false)} />}
    </Horizontal>
  )
}
